/**
 * Initialize the desktop menu
 */
export function initializeDesktopMenu() {
  const buttons = document.querySelectorAll(".container__buttons");
  const overlay = document.querySelector("#menu_overlay");

  // Close menu when hovering over overlay
  overlay.addEventListener("mouseover", function () {
    closeAllDropdowns();
  });

  // Close menu when scrolling the page
  window.addEventListener("scroll", function (event) {
    closeAllDropdowns();
  });

  // Add event listeners to all dropdown buttons
  if (buttons) {
    for (const button of buttons) {
      const dropdown = button.querySelector(".dropdown");

      if (dropdown) {
        button.addEventListener("mouseover", function () {
          showDropdown(dropdown);
        });
        button.addEventListener("click", function () {
          showDropdown(dropdown);
        });
      }
    }
  }

  // Add event listeners to all dropdown tabs
  const navButtons = document.querySelectorAll(".nav_button");
  if (navButtons) {
    for (const button of navButtons) {
      button.addEventListener("click", function () {
        const navButtons = document.querySelectorAll(".nav_button");
        for (const buttonLoop of navButtons) {
          buttonLoop.classList.remove("active");
        }
        button.classList.add("active");

        const tabId = button.getAttribute("dropdown-target");
        switchTab(tabId);
      });
    }
  }
}

/**
 * Initialize the mobile menu
 */
export function initializeMobileMenu() {
  // Toggle mobile menu when clicking on the hamburger menu
  document
    .querySelector(".hamburger")
    .addEventListener("click", function (event) {
      toggleMobileMenu();
    });

  // Open menu list
  const items = document.querySelectorAll(".mobile_menu_navigation_link");
  if (items) {
    for (const item of items) {
      item.addEventListener("click", function (event) {
        const target = event.target.getAttribute("target");
        openMobileMenuSection(target);
      });
    }
  }

  // Back button
  const backButtons = document.querySelectorAll(".mobile_menu .back");
  if (backButtons) {
    for (const button of backButtons) {
      button.addEventListener("click", function (event) {
        backToMobileMenu();
      });
    }
  }
}

function backToMobileMenu() {
  const header = document.querySelector(".mobile_menu .header");
  const navigation = document.querySelector(".mobile_menu .navigation");
  const content = document.querySelector(".mobile_menu .content");

  header.style.display = "block";
  navigation.style.display = "block";
  content.style.display = "none";
}

function openMobileMenuSection(target) {
  const header = document.querySelector(`.mobile_menu .header`);
  const navigation = document.querySelector(`.mobile_menu .navigation`);
  const content = document.querySelector(`.mobile_menu .content`);
  const section = document.querySelector(`.mobile_menu_${target}`);
  const firstNavButton = section.getElementsByTagName("button")[0];

  // Hide all other sections
  const sections = document.querySelectorAll(".mobile_menu_content");
  for (const section of sections) {
    section.style.display = "none";
  }

  header.style.display = "none";
  navigation.style.display = "none";
  content.style.display = "block";
  section.style.display = "block";
  firstNavButton.click();
}

function toggleMobileMenu() {
  const mobileMenu = document.querySelector(".mobile_menu");
  const mobileMenuButton = document.querySelector(".hamburger");

  if (mobileMenu.style.display === "block") {
    mobileMenu.style.display = "none";
    mobileMenuButton.classList.remove("active");
    document.body.style.overflow = "auto";
  } else {
    mobileMenu.style.display = "block";
    mobileMenuButton.classList.add("active");
    document.body.style.overflow = "hidden";
  }
}

function switchTab(tabId) {
  const tab = document.querySelector(`#${tabId}`);
  tab.classList.add("active");

  const tabs = document.querySelectorAll(".dropdown_tab");
  for (const tab of tabs) {
    if (tab.getAttribute("id") !== tabId) {
      tab.classList.remove("active");
    }
  }
}

function showDropdown(dropdown) {
  const overlay = document.querySelector("#menu_overlay");
  const dropdownId = dropdown.getAttribute("id");
  const dropdowns = document.querySelectorAll(".dropdown");
  const buttonId = dropdownId.split("_").pop();
  const button = document.getElementById(`dropdown_btn_${buttonId}`);

  if (window.lastDropdown !== dropdown) {
    const firstTab = dropdown.querySelectorAll(".dropdown_tab")[0];
    if (!firstTab.classList.contains("active")) {
      const firstTabButton = dropdown.querySelectorAll(".nav_button")[0];
      if (!firstTabButton.classList.contains("active")) {
        firstTabButton.click();
      }
    }
    window.lastDropdown = dropdown;
  }

  button.classList.add("active");
  dropdown.style.display = "block";
  overlay.style.display = "block";
  let arrowLeft;

  switch (buttonId) {
    case "1":
      arrowLeft = "0px";
      break;
    case "2":
      arrowLeft = "100px";
      break;
    case "3":
      arrowLeft = "190px";
      break;
    case "4":
      arrowLeft = "295px";
      break;
    case "5":
      arrowLeft = "420px";
      break;
  }

  document.querySelectorAll(".dropdown__up_arrow")[buttonId - 1].style.left =
    arrowLeft;

  // Hide other dropdowns
  for (const dropdown of dropdowns) {
    if (dropdown.getAttribute("id") !== dropdownId) {
      const buttonId = dropdown.getAttribute("id").split("_").pop();
      const button = document.getElementById(`dropdown_btn_${buttonId}`);
      button.classList.remove("active");
      dropdown.style.display = "none";
    }
  }
}

function closeAllDropdowns() {
  const dropdowns = document.querySelectorAll(".dropdown");
  const overlay = document.querySelector("#menu_overlay");

  for (const dropdown of dropdowns) {
    const buttonId = dropdown.getAttribute("id").split("_").pop();
    const button = document.getElementById(`dropdown_btn_${buttonId}`);
    button.classList.remove("active");
    dropdown.style.display = "none";
  }

  overlay.style.display = "none";
}
