/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

/**
 * Return initialized XMLHttpRequest
 *
 * @param {string} method Request method
 * @param {string} url Request URL
 * @return {XMLHttpRequest}
 */
function getXHR(method, url) {
  const xhr = new XMLHttpRequest();
  xhr.open(method.toUpperCase(), url);
  if (method.toUpperCase() === 'POST') {
    xhr.setRequestHeader('Content-Type', 'application/json');
  }
  return xhr;
}

/**
 * Send a request using POST method to the given URL using the given body
 *
 * @param {string} url URL to send data
 * @param {object} body Body to send with
 *
 * @return {Promise<any>}
 */
export function post(url, body) {
  return new Promise((resolve, reject) => {
    const xhr = getXHR('POST', url);
    xhr.send(JSON.stringify(body));
    xhr.onload = () => {
      const responseText = xhr.responseText === ''
        ? '{}'
        : xhr.responseText;
      if (xhr.status === 200) {
        resolve(JSON.parse(responseText));
      } else {
        try {
          const json = JSON.parse(responseText);
          reject(json);
        } catch (e) {
          reject({ responseText });
        }
      }
    }
  });
}

/**
 * Send a request using GET method to the given URL
 *
 * @param {string} url
 * @return {Promise<any>}
 */
export function get(url) {
  return new Promise((resolve, reject) => {
    const xhr = getXHR('GET', url);
    xhr.send();
    xhr.onload = () => {
      const responseText = xhr.responseText === ''
        ? '{}'
        : xhr.responseText;
      if (xhr.status === 200) {
        resolve(JSON.parse(responseText));
      } else {
        reject(JSON.parse(responseText));
      }
    }
  })
}
