// import modules that define the main functionalities of the app
import Autocompleter from './modules/autocompleter';
import Clicks from './modules/clicks';
import Ratings from './modules/ratings';

export default class App {
  /**
   * Autocompleter module instance
   *
   * @type {Autocompleter}
   */
  autocompleter;

  /**
   * Clicks module instance
   *
   * @type {Clicks}
   */
  clicks;

  /**
   * Ratings module instance
   *
   * @type {Ratings}
   */
  ratings;

  constructor(options = {}) {
    this.options = Object.assign(
      {},
      {
        // define current environment (defined in environment variable as: development, staging, prod)
        environment: inbenta.seo.env.INBENTA_ENV,
      },
      options,
    );
  }

  initialize() {
    this.autocompleter = new Autocompleter();
    this.clicks = new Clicks();
    this.ratings = new Ratings();
  }
}
