/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import App from "./app";
import bindClickEvent from "./helpers/clicks";
import { initializeDesktopMenu, initializeMobileMenu } from "./helpers/menu";

function main() {
  const app = new App();
  app.initialize();

  initializeDesktopMenu();
  initializeMobileMenu();

  console.log("App initialized...", app);

  let contents = document.querySelectorAll('.inbenta-km__faq__title');
  if (contents) {
    contents.forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        bindClickEvent(element, false);
      });
    })
  }
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", main);
} else {
  main();
}
