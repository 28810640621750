import { click } from './tracking';
import he from 'he';

/**
 * Bind the click event on the given element
 *
 * @param {HTMLElement|Element} element Element to bind click event to
 */
export default function bindClickEvent(element, redirect = true) {
  const trackingKey = element.getAttribute('data-tracking') ?? 'external_click';

  let trackingData = null;
  try {
    trackingData = JSON.parse(element.getAttribute('data-trackdata'));
  } catch (e) {
    trackingData = JSON.parse(he.decode(element.getAttribute('data-trackdata')));
  }

  const href = element.getAttribute(element.nodeName === 'A' ? 'href' : 'data-href');

  if (element.classList.contains('inbenta-km__autocompleter__link')) {
    trackingData.autocomplete = true;
    trackingData.query = document.getElementById('inbenta-km__search__form__input').value;
  }

  // send click tracking
  click(trackingKey, trackingData);

  if (redirect) window.location.href = href;
}
